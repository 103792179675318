//require('babel-polyfill')
//require("animate.css/animate.css")
//require("bootstrap/dist/css/bootstrap.min.css")
//require("./styles/vendor/slick.css")

// JQUERY & PLUGINS
//require("!!script-loader!lozad")
require("!!script-loader!raphael");
require("!!script-loader!jquery");
require("!!script-loader!intl-tel-input/build/js/intlTelInputWithUtils.min");
//require('!!script-loader!intl-tel-input/build/css/intlTelInput.css');
require("!!script-loader!jquery-ui/ui/widgets/datepicker")
//require("!!script-loader!jquery.cookie")
//require("!!script-loader!jquery.localscroll")
require("!!script-loader!slick-carousel")
//require("!!script-loader!swiper")
//require("micromodal")
//require("@easepick/bundle")
//require("headroom.js")
//require("!!script-loader!jquery.scrollto");
//require("!!script-loader!scrolltofixed");
//require("!!script-loader!./js/vendor/fitvids");
//require("!!script-loader!./js/vendor/jquery.formatCurrency");


// BOOTSTRAP
//require("bootstrap")
require('events')
//require("./js/PromiseConfig")